//
// SweetAlert2.scss
//

// Popup
.swal2-popup {
	background-color: $card-bg;
	@include border-radius($border-radius);

	.swal2-title {
		font-weight: 500;
		font-size: 1.3rem;
		color: var(--#{$prefix}dark);
	}

	.swal2-html-container,
	.swal2-content {
		font-weight: normal;
		font-size: 1.1rem;
		margin-top: 1.5rem;
		color: var(--#{$prefix}tertiary-color);
	}

	.swal2-footer {
		border-top-color: var(--#{$prefix}border-color) !important;
	}

	.btn {
		margin: 15px 5px 0;
	}

	.swal2-styled {
		&:focus {
			box-shadow: none;
		}
	}

	.swal2-actions {
		margin: 1.5rem auto 1rem auto;
	}
}

// Container
.swal2-container {
	&.swal2-shown {
		background-color: var(--#{$prefix}tertiary-color);
		opacity: 0.5;
	}

	// Content
	.swal2-html-container {
		max-height: 200px;
		overflow: auto;
	}
}


// State Colors
.swal2-icon {

	// Warning
	&.swal2-warning {
		border-color: $warning;
		color: $warning;
	}

	// Error
	&.swal2-error {
		border-color: $danger;
		color: $danger;

		[class^=swal2-x-mark-line] {
			background-color: rgba($danger, 0.75);
		}
	}

	// Success
	&.swal2-success {
		border-color: $success;
		color: $success;

		[class^=swal2-success-line] {
			background-color: $success;
		}

		.swal2-success-ring {
			border-color: rgba($success, 0.3);
		}
	}

	// Info
	&.swal2-info {
		border-color: $info;
		color: $info;
	}

	// Question
	&.swal2-question {
		border-color: $primary;
		color: $primary;
	}
}