// 
// google-map.scss
// 


.gmaps,
.gmaps-panaroma {
  height: 300px;
  background: $gray-100;
  border-radius: 3px;
}