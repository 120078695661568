//
// modal.scss
//

// Title text within header
.modal-title {
    margin-top: 0;
}

// Modal full width
.modal-full-width {
    width: 95%;
    max-width: none;
}

// Modal Positions
.modal-top {
    margin: 0 auto;
}


// Bottom modal
.modal-bottom {
    display: flex;
    flex-flow: column nowrap;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 100%;
    margin: 0 auto;
    align-content: center;
}

// Colored modal header
.modal-colored-header {
    color: $white;
    border-radius: 0;

    .close {
        color: $white !important;
    }
}

// Full filled modals
.modal-filled {
    color: $white;

    .modal-header {
        background-color: rgba($white, 0.07);
    }

    .btn-close {
        color: $white !important;
    }
}
