// 
// _theme-mode.scss
// 


/* Root Prefix Variable */
:root {
    --#{$prefix}logo-lg-height:                15px;
    --#{$prefix}logo-sm-height:                24px;

    --#{$prefix}main-nav-width:               245px;
    --#{$prefix}main-nav-width-sm:             75px; 
    --#{$prefix}main-nav-item-icon-size:       18px;
    --#{$prefix}main-nav-item-font-size:       15px;
    --#{$prefix}main-nav-item-padding-x:       15px;
    --#{$prefix}main-nav-item-padding-y:       10px;
    --#{$prefix}main-nav-item-margin-y:       2px;

    --#{$prefix}topbar-height:                 70px;
    --#{$prefix}footer-height:                 60px;
    
    // Heading Variable
    --#{$prefix}headings-color:                 #313b5e;

    --#{$prefix}input-border-color:             #d8dfe7;
    --#{$prefix}input-focus-border-color:       #b0b0bb;
}


/* Color system - Dark Mode only */
html[data-bs-theme="dark"],
[data-bs-theme="dark"] {

    --#{$prefix}border-color:                   #2f3944;

    --#{$prefix}light:                           #{$body-tertiary-bg-dark};
    --#{$prefix}dark:                            #{$body-tertiary-color-dark};
    
    --#{$prefix}light-rgb:                       #{to-rgb($body-tertiary-bg-dark)};
    --#{$prefix}dark-rgb:                        #{to-rgb($body-tertiary-color-dark)};

    // Heading Variable
    --#{$prefix}headings-color:                  #{$body-color-dark};

    --#{$prefix}input-border-color:             #3a4551;
    --#{$prefix}input-focus-border-color:       #4a5663;
}


/* Main Menu Light */
html[data-menu-color="light"]{
    --#{$prefix}main-nav-bg:                               #ffffff;
    --#{$prefix}main-nav-item-color:                       #8486a7;
    --#{$prefix}main-nav-item-hover-bg:                    #f3f1fa;
    --#{$prefix}main-nav-item-hover-color:                 #3d4756;
    --#{$prefix}main-nav-border-color:                     #eaedf1;
}


/* Main Menu Dark */
html[data-menu-color="dark"] {
    --#{$prefix}main-nav-bg:                               #2d3d4d;
    --#{$prefix}main-nav-item-color:                       #afb9cf;
    --#{$prefix}main-nav-item-hover-bg:                    #283643;
    --#{$prefix}main-nav-item-hover-color:                 #{$white};
    --#{$prefix}main-nav-border-color:                     #2f3944;
}


/* Main Menu Dark (Dark Mode Only) */
html[data-bs-theme="dark"][data-menu-color="dark"],
html[data-bs-theme="dark"][data-menu-color="light"] {
    --#{$prefix}main-nav-bg:                               #282f36;
    --#{$prefix}main-nav-item-color:                       #afb9cf;
    --#{$prefix}main-nav-item-hover-bg:                    #303943;
    --#{$prefix}main-nav-item-hover-color:                 #{$white};
    --#{$prefix}main-nav-border-color:                     #2f3944;
}


/* Topbar Light */
html[data-topbar-color="light"] {
    --#{$prefix}topbar-bg:                                 #{$white};
    --#{$prefix}topbar-item-color:                         #707793;
    --#{$prefix}topbar-search-bg:                          #f3f3f9;
}


/* Topbar Dark */
html[data-topbar-color="dark"] {
    --#{$prefix}topbar-bg:                                 #2d3d4d;
    --#{$prefix}topbar-item-color:                         #afb9cf;
    --#{$prefix}topbar-search-bg:                          #283643;
}


/* Topbar Dark (Dark Mode Only) */
html[data-bs-theme="dark"][data-topbar-color="dark"], 
html[data-bs-theme="dark"][data-topbar-color="light"] {
    --#{$prefix}topbar-bg:                                 #282f36;
    --#{$prefix}topbar-item-color:                         #afb9cf;
    --#{$prefix}topbar-search-bg:                          #303943;
}