//
// type.scss
//

// Font sizes (Custom)

$font-size-mixing: 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 48,60;

@each $font-size-mixing in $font-size-mixing {
    .fs-#{$font-size-mixing} {
        font-size: #{$font-size-mixing}px !important;
    }
}

// Font Weight
.fw-medium {
    font-weight: $font-weight-medium;
}

// text utilities
.text-unline-dashed {
    border-bottom: $border-width dashed $border-color !important;
}

.text-dark {
    color: var(--#{$prefix}headings-color) !important;
}