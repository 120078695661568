//
// _reboot.scss
//
 
body {
    overflow-x: hidden;
}

.row>* {
    position: relative;
}

.scroll-hidden {
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        appearance: none;
    }

    &::-webkit-scrollbar:vertical {
        width: 0px;
    }
}


// Iconify
iconify-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
}
